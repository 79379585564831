import Api from "@/services/api";

export default {
    getContent(parent) {
        return Api().get("/ged/content/" + parent).then(res => {
            return res.data;
        });
    },
    deleteMultiple(data) {
        return Api().post("/ged/delete", data).then(res => res.data);
    },
    moveMultiple(data) {
        return Api().post("/ged/move", data).then(res => res.data);
    },
    getContentByName(name) {
        return Api().get("/ged/content-name/" + name).then(res => res.data);
    },
    getAllContent() {
        return Api().get("/ged/content").then(res => res.data);
    }
}