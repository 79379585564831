import Api from "@/services/api";
import imgMixin from "@mixins/imgMixin";
import {downloadData} from "@utils";

export default {
    create(fic) {
        let files = fic.files;
        let formData = new FormData();
        formData.append(
            'fic',
            new Blob([JSON.stringify(fic)], {
                type: 'application/json',
            })
        );

        if (null != files) {
            files.forEach(file => {
                formData.append('files', file);
            });
        }
        return Api().post("/fic", formData).then(res => res);
    },
    update(fic) {
        let files = fic.files;
        let formData = new FormData();
        formData.append(
            'fic',
            new Blob([JSON.stringify(fic)], {
                type: 'application/json',
            })
        );

        if (null != files) {
            files.forEach(file => {
                formData.append('files', file);
            });
        }
        return Api().put("/fic", formData).then(res => res);
    },
    delete(id) {
        return Api().delete("/fic/" + id).then(res => res);
    },
    getDocument(fic) {
        if (null == fic) {
            return null;
        }

        let fileType = imgMixin.methods.getThumbnailAlt(fic.mimetype);
        let type;
        if (fileType === "pdf" || fileType === "image") {
            type = "inline";
        } else {
            type = "attachment";
        }

        let url = "/fic/" + fic.id + "/consultation?type=" + type;

        if (type === "attachment") return Api().get(url, {responseType: 'blob'}).then(res => downloadData(res));
        if (type === "inline") return Api().get(url, {responseType: 'blob'}).then(res =>
            window.open(URL.createObjectURL(res.data), '_blank')
        );
    },
    getConsultationURLPublic(fic) {
        if (null == fic) {
            return null;
        }

        let fileType = imgMixin.methods.getThumbnailAlt(fic.mimetype);
        let type;
        if (fileType === "pdf" || fileType === "image") {
            type = "inline";
        } else {
            type = "attachment";
        }

        return process.env.VUE_APP_PUBLIC_API_URL + "/fic/" + fic.uuid + "/consultation?type=" + type;
    },
    copyLinkToClipboard(fic) {
        let url = this.getConsultationURLPublic(fic);
        return navigator.clipboard.writeText(url);
    }
}