<script>

import AkLabel from "@components/general/AkLabel";

export default {
  components: {AkLabel},
  inject: {'pSubmitted' : {default: null}, 'pDisabled' : {default: null}},
  props: {
    modelValue: {},
    label: {
      type: String,
      required: false,
    },
    accept: {
      type: String,
      required: false,
    },
    className: {
      type: String,
      required: false,
      default: "col-md-4 col-6"
    },
    disabled: {
      type: Boolean,
      required: false,
      default: undefined
    },
    fileLimit: {
      type: Number,
      required: false,
      default: null
    },
    maxFileSize: {
      type: Number,
      required: false,
      default: 10000000
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  methods: {
    selectFiles(event) {
      this.currentValue = event.files;
    },
    removeFile(event) {
      this.currentValue = event.files;
    },
    clearFiles() {
      this.currentValue = [];
    },
    clear() {
      this.$refs.file.clear();
    },
    uploader(event) {
      this.currentValue = event.files;
    }
  },
  computed: {
    currentValue: {
      get() {
        return this.modelValue;
      },
      set(val) {
        this.$emit('update:modelValue', val);
      }
    },
    isDisabled() {
      if (this.disabled !== undefined) return this.disabled;
      if (this.pDisabled !== undefined) return this.pDisabled;
      return false;
    },
  }
}
</script>

<template>
  <div :class="this.className" class="form-group">
    <AkLabel>{{ this.label }}</AkLabel>
    <FileUpload ref="file"
                name="file"
                :chooseLabel="$t('choose_file')"
                :cancelLabel="$t('cancel_file')"
                :multiple=multiple
                :disabled="isDisabled"
                :showUploadButton=false
                :customUpload="true"
                :fileLimit="fileLimit"
                :maxFileSize="maxFileSize"
                invalidFileSizeMessage="{0}: Taille de fichier invalide. La taille devrait être plus petite que {1}"
                :accept="accept"
                @select=selectFiles
                @remove=removeFile
                @clear=clearFiles
                @uploader=uploader>
      <template #empty>
        <p>{{ $t('drag_files') }}</p>
      </template>
    </FileUpload>
  </div>
</template>