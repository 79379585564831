import Api from "@/services/api";

export default {
    findAll() {
        return Api().get("/folders").then(res => {
            return res.data;
        });
    },
    create(folder) {
        return Api().post("/folder", folder).then(res => res.data);
    },
    update(folder) {
        return Api().put("/folder", folder).then(res => res.data);
    },
    delete(id) {
        return Api().delete("/folder/" + id).then(res => res.data);
    },
    findFoldersByParentId(id) {
        return Api().get("/folder/" + id + "/childrens").then(res => res.data);
    },
    findAuthorizedFolders(parentIds) {
        return Api().post("/folders/authorized", parentIds).then(res => res.data);
    },
    findPath(id) {
        return Api().get("/folder/" + id + "/path").then(res => res.data);
    }
}