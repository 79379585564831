<script>
import AkFormView from "@components/layout/AkFormView.vue";
import roleMixin from "@mixins/roleMixin";
import randomRef from "@mixins/randomRef";
import gedService from "@services/gedService";
import messageMixin from "@mixins/messageMixin";
import imgMixin from "@mixins/imgMixin";
import AkDialog from "@components/general/AkDialog.vue";
import AkYesNoButton from "@components/input/AkYesNoButton.vue";
import AkInputText from "@components/input/AkInputText.vue";
import folderService from "@services/folderService";
import AkFileUpload from "@components/input/AkFileUpload.vue";
import ficService from "@services/ficService";
import dateFormatter from "@mixins/dateFormatter";
import AkCheckbox from "@components/input/AkCheckbox.vue";
import {requiredIf} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import AkDropdown from "@components/input/AkDropdown.vue";

export default {
  name: "GedSearchView",
  components: {AkDropdown, AkCheckbox, AkInputText, AkDialog, AkFormView},
  mixins: [roleMixin, randomRef, messageMixin, imgMixin, dateFormatter],
  metaInfo() {
    return {
      title: "ged.search",
    }
  },
  setup() {
    return {v$: useVuelidate()}
  },
  data() {
    return {
      currentFolderId: 'root',
      content: {
        folders: [],
        fics: []
      },
      selectedDocuments: null,
      selectedDocument: null,
      loading: true,
      documents: [],
      folders: [],
      selectedFolderChildrensIds: [],
      newDocument: {},
      authorizedFolders: [],
      newParentId: null,
      search: ''
    };
  },
  validations() {
    return {
      newDocument: {
        name: {
          required: requiredIf(() => (this.newDocument.folder || this.selectedDocuments !== null) && this.$refs.addDialog.display)
        },
        files: {
          required: requiredIf(() => !this.newDocument.folder && this.$refs.addDialog.display)
        }
      },
      newParentId: {
        // dialog ouvert
        required: requiredIf(() => this.selectedDocuments !== null && this.$refs.moveMultipleDialog.display)
      },
    }
  },
  mounted() {
    if (this.$route.query.search !== undefined) {
      this.search = this.$route.query.search;
    }

    this.v$.$reset();
    this.reloadList();
  },
  methods: {
    rowSelected(data) {
      let type = this.getThumbnailAlt(data.mimetype);

      if (type === 'folder') {
        this.$router.replace({name: 'documents', query: {folderId: data.id}});
      } else {
        this.getDocument(data);
      }
    },
    getDocument(doc) {
      ficService.getDocument(doc);
    },
    getConsultationURLPublic(document) {
      return ficService.getConsultationURLPublic(document);
    },
    reload() {
      this.loading = true;
      this.content = {
        folders: [],
        fics: []
      };

      let promises = [];

      if (this.search.length !== 0) {
        let p1 = gedService.getContentByName(this.search).then((data) => {
          this.content = data;
          this.sort(this.content.folders);
          this.sort(this.content.fics);
        });
        promises.push(p1);
      }

      let p2 = folderService.findAll().then((data) => {
        this.folders = [];
        this.folders.push({id: -1, name: this.$t('ged.root_folder')}); // Pour mettre le dossier/fichier à la racine et avoir un affichage correct dans le dropdown de modification

        for (let folder of data) {
          this.folders.push(folder);
        }
      });
      promises.push(p2);

      return Promise.all(promises);
    },
    sort(items) {
      // Tri des dossiers et fichiers par nom
      return items.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });
    },
    reloadList() {
      this.reload().finally(() => {
        this.selectedDocuments = null;
        this.loading = false;
        document.getElementById('searchBar').focus();
      });
    },
    getCategory(document) {
      if (document.mimetype === null || document.mimetype === undefined) {
        return "folder";
      } else {
        return "fic";
      }
    },
    openUpdateDialog(doc) {
      this.v$.$reset();
      this.selectedDocument = JSON.parse(JSON.stringify(doc));
      if (this.getCategory(this.selectedDocument) === 'folder') {
        folderService.findFoldersByParentId(this.selectedDocument.id).then((data) => {
          for (let folder of data) {
            this.selectedFolderChildrensIds.push(folder.id);
          }
        });
      }

      this.$refs.updateDialog.show();
    },
    updateDocument() {
      if (this.selectedDocument.parentId === -1) { // Pour mettre le dossier/fichier à la racine
        this.selectedDocument.parentId = null;
      }

      if (this.selectedDocument.folderId === -1) { // Pour mettre le dossier/fichier à la racine
        this.selectedDocument.folderId = null;
      }

      if (this.selectedDocument.mimetype === null || this.selectedDocument.mimetype === undefined) {
        folderService.update(this.selectedDocument).then(() => {
          this.reloadList();
          this.$refs.updateDialog.hide();

          this.getRef().success(this.$t('ged.folder_updated'));
        }).catch(() => {
          this.getRef().error(this.$t('ged.error_updated'));
        });
      } else {
        ficService.update(this.selectedDocument).then(() => {
          this.reloadList();
          this.$refs.updateDialog.hide();

          this.getRef().success(this.$t('ged.fic_updated'));
        }).catch(() => {
          this.getRef().error(this.$t('ged.error_updated'));
        });
      }
    },
    openDeleteDialog(doc) {
      this.selectedDocument = JSON.parse(JSON.stringify(doc));
      this.$refs.deleteDialog.show();
    },
    deleteDocument() {
      if (this.selectedDocument.mimetype === null || this.selectedDocument.mimetype === undefined) {
        folderService.delete(this.selectedDocument.id).then(() => {
          this.reloadList();
          this.$refs.deleteDialog.hide();

          this.getRef().success(this.$t('ged.folder_deleted'));
        }).catch(() => {
          this.getRef().error(this.$t('ged.error_deleted'));
        });
      } else {
        ficService.delete(this.selectedDocument.id).then(() => {
          this.reloadList();
          this.$refs.deleteDialog.hide();

          this.getRef().success(this.$t('ged.fic_deleted'));
        }).catch(() => {
          this.getRef().error(this.$t('ged.error_deleted'));
        });
      }
    },
    doesVuelidateErrorsContains(property) {
      for (let error of this.v$.$errors) {
        if (error.$property === property) {
          return true;
        }
      }
      return false;
    },
    openDeleteMultipleDialog() {
      this.$refs.deleteMultipleDialog.show();
    },
    openMoveMultipleDialog() {
      this.v$.$reset();
      this.newParentId = null;

      let folders = this.selectedDocuments.filter(doc => doc.mimetype === null || doc.mimetype === undefined);
      let ids = folders.map(f => f.id);

      folderService.findAuthorizedFolders(ids).then((data) => {
        this.authorizedFolders = [{id: -1, name: this.$t('ged.root_folder')}]; // Pour mettre le dossier/fichier à la racine et avoir un affichage correct dans le dropdown de modification
        this.authorizedFolders.push(...data);
      }).finally(() => {
        this.$refs.moveMultipleDialog.show();
      });
    },
    deleteDocuments() {
      let folders = this.selectedDocuments.filter(doc => doc.mimetype === null || doc.mimetype === undefined);
      let fics = this.selectedDocuments.filter(doc => doc.mimetype !== null && doc.mimetype !== undefined);

      let data = {
        folders: folders,
        fics: fics
      };

      gedService.deleteMultiple(data).then(() => {
        this.reloadList();
        this.$refs.deleteMultipleDialog.hide();
        this.selectedDocuments = [];

        this.getRef().success(this.$t('ged.deleted_multiple'));
      }).catch(() => {
        this.getRef().error(this.$t('ged.error_deleted_multiple'));
      });
    },
    moveDocuments() {
      if (!this.validate()) return;

      let folders = this.selectedDocuments.filter(doc => doc.mimetype === null || doc.mimetype === undefined);
      let fics = this.selectedDocuments.filter(doc => doc.mimetype !== null && doc.mimetype !== undefined);

      if (this.newParentId === -1) { // Pour mettre le dossier/fichier à la racine
        this.newParentId = null;
      }

      let data = {
        folders: folders,
        fics: fics,
        newParentId: this.newParentId
      };

      gedService.moveMultiple(data).then(() => {
        this.reloadList();
        this.$refs.moveMultipleDialog.hide();
        this.selectedDocuments = [];

        this.getRef().success(this.$t('ged.moved_multiple'));
      }).catch(() => {
        this.getRef().error(this.$t('ged.error_moved_multiple'));
      });
    },
    validate() {
      this.getRef().resetMessages();
      this.v$.$touch();
      if (this.v$.$error) {
        for (let error of this.v$.$errors) {
          console.log(error);
          this.getRef().error(this.$t('ged.error_missing_' + error.$property));
        }
        return false;
      }
      return true;
    },
    copyLinkToClipboard(document) {
      ficService.copyLinkToClipboard(document).then(() => {
        this.getRef().success(this.$t('ged.link_copied'));
      }).catch(() => {
        this.getRef().error(this.$t('ged.link_copy_error'));
      });
    },
    goToDocuments() {
      this.$router.push({name: 'documents'});
    }
  },
  computed: {
    title() {
      return this.$t('ged.search');
    },
    isSelectedDocumentsEmpty() {
      return this.selectedDocuments === null || this.selectedDocuments === undefined || this.selectedDocuments.length === 0;
    },
  },
  watch: {
    content: {
      handler: function (val) {
        this.documents = [...this.content.folders, ...this.content.fics];
      },
      deep: true
    }
  }
}
</script>

<template>
  <AkFormView :ref="ref" :title="title" :displayGoBack=false>
    <template v-slot:header>
      <div class="row">
        <div class="col-lg-12">
          <div class="d-flex justify-content-between">
            <div>
              <span class="p-input-icon-left search-bar-desktop">
                <i class="pi pi-search"/>
                <InputText id="searchBar" v-model="search" @keyup="reloadList()" :placeholder="$t('search_here')" title="Recherche de documents selon leur nom"/>

                <button class="btn btn-inverse-primary btn-xs ml-1" @click="goToDocuments()"
                        title="Retour à la bibliothèque">
                  <i class="fe fe-arrow-up"></i>
                </button>
              </span>
            </div>
            <div>
              <button class="btn btn-inverse-primary btn-xs mr-1" @click="reloadList" title="Recharger la liste">
                <i class="fe fe-refresh-cw"/>
              </button>
              <template v-if="canManageGED()">
                <button class="btn btn-inverse-primary btn-xs mr-1" @click="openMoveMultipleDialog"
                        title="Déplacer la sélection"
                        v-if="!isSelectedDocumentsEmpty">
                  <i class="fe fe-move"/>
                </button>
                <button class="btn btn-inverse-danger btn-xs" @click="openDeleteMultipleDialog"
                        title="Supprimer la sélection"
                        v-if="!isSelectedDocumentsEmpty">
                  <i class="fe fe-trash"/>
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:form>
      <div class="row">
        <div class="col-lg-12">
          <div class="table-responsive" id="ged-table">
            <DataTable
                :always-show-paginator="false"
                :loading="loading"
                :paginator="false"
                :value="documents"
                class="table"
                responsiveLayout="scroll"
                v-model:selection="selectedDocuments"
                dataKey="dataKey"
                stripedRows>
              <template #empty>
                {{ $t("ged.search_list_empty") }}
              </template>
              <template v-if="canManageGED()">
                <Column selectionMode="multiple" header-style="width: 3rem;"></Column>
              </template>
              <Column field="name" :header="$t('ged.doc_name')" header-style="width: 50%;">
                <template #body="slotProps">
                  <div @click="rowSelected(slotProps.data)" style="cursor: pointer;" :title="slotProps.data.name">
                    <img :alt="getThumbnailAlt(slotProps.data.mimetype)" :src="getThumbnail(slotProps.data.mimetype)"
                         class="mr-3"
                         style="max-width: 30px; margin-left: 0.50rem;"/>
                    {{ slotProps.data.name + (slotProps.data?.extension !== undefined ? '.' + slotProps.data.extension : '') }}
                  </div>
                </template>
              </Column>
              <Column field="dateCreated" :header="$t('ged.date_created')">
                <template #body="slotProps">
                  {{ formatDate(slotProps.data.dateCreated) }}
                </template>
              </Column>
              <Column field="creatorFullName" :header="$t('ged.author')">
                <template #body="slotProps">
                  {{ slotProps.data.creatorFullName }}
                </template>
              </Column>
              <Column field="shared" :header="$t('ged.shared')">
                <template #body="slotProps">
                  <span v-if="getCategory(slotProps.data) !== 'folder' && slotProps.data.shared">
                    <i class="fe fe-check"/>
                  </span>
                </template>
              </Column>
              <Column>
                <template #body="slotProps">
                  <div class="d-flex justify-content-end">
                    <button v-if="getCategory(slotProps.data) !== 'folder' && slotProps.data.shared" class="btn btn-inverse-primary btn-xs mr-1"
                            @click="copyLinkToClipboard(slotProps.data)" title="Copier le lien public du document">
                      <i class="fe fe-clipboard"/>
                    </button>
                    <button v-if="getCategory(slotProps.data) !== 'folder'" class="btn btn-inverse-primary btn-xs mr-1"
                            @click="getDocument(slotProps.data)" title="Télécharger le document">
                      <i class="fe fe-download"/>
                    </button>
                    <template v-if="canManageGED()">
                      <button class="btn btn-inverse-primary btn-xs mr-1" @click="openUpdateDialog(slotProps.data)"
                              title="Modifier le document">
                        <i class="fe fe-edit"/>
                      </button>
                      <button class="btn btn-inverse-danger btn-xs" @click="openDeleteDialog(slotProps.data)"
                              title="Supprimer le document">
                        <i class="fe fe-trash"/>
                      </button>
                    </template>
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </template>
  </AkFormView>
  <AkDialog ref="updateDialog"
            :cancel-label="$t('cancel')"
            :title="$t('ged.update_document')"
            :validate-label="$t('validate')"
            width="650px"
            @validate="updateDocument()">
    <div class="row d-flex flex-column">
      <AkInputText v-model="selectedDocument.name"
                   :class-name="'col-lg-12 ' + (doesVuelidateErrorsContains('name') ? 'datatable-validation-error' : '')"
                   :label="getCategory(selectedDocument) === 'folder' ? $t('ged.folder_name') : $t('ged.fic_name')"
                   required/>
      <AkCheckbox v-if="getCategory(selectedDocument) !== 'folder'" class-name="col-lg-12"
                  v-model="selectedDocument.shared" :label="$t('ged.shared')"/>

      <AkDropdown
          :options="folders.filter(f => f.id !== selectedDocument.id && !this.selectedFolderChildrensIds.includes(f.id))"
          option-value="id" option-label="name" v-if="getCategory(selectedDocument) === 'folder'"
          v-model="selectedDocument.parentId" :label="$t('ged.parent_folder')"/>
      <AkDropdown :options="folders" option-value="id" option-label="name"
                  v-if="getCategory(selectedDocument) !== 'folder'"
                  v-model="selectedDocument.folderId" :label="$t('ged.parent_folder')"/>

      <div class="col-lg-12" v-if="getCategory(selectedDocument) !== 'folder' && selectedDocument.shared">
        Lien de partage public: <br>{{ getConsultationURLPublic(selectedDocument) }}
      </div>
    </div>
  </AkDialog>

  <AkDialog ref="deleteDialog"
            :cancel-label="$t('cancel')"
            :title="$t('ged.delete_document')"
            :validate-label="$t('validate')"
            width="650px"
            @validate="deleteDocument()">
    <div class="row d-flex flex-column">
      <div class="col-lg-12">
        {{
          $t('ged.delete_document_confirm') + (getCategory(selectedDocument) === 'folder' ? $t('ged.folder') : $t('ged.fic')) + " " + selectedDocument.name + "?"
        }}
      </div>
    </div>
  </AkDialog>

  <AkDialog ref="deleteMultipleDialog"
            :cancel-label="$t('cancel')"
            :title="$t('ged.delete_documents')"
            :validate-label="$t('validate')"
            width="650px"
            @validate="deleteDocuments()">
    <div class="row d-flex flex-column">
      <div class="col-lg-12">
        {{
          $t('ged.delete_documents_confirm') + ' :'
        }}
        <span v-for="doc in selectedDocuments" :key="doc">
          <br>
          <img :alt="getThumbnailAlt(doc.mimetype)" :src="getThumbnail(doc.mimetype)"
               class="mr-3"
               style="max-width: 30px;"/>
          {{ getCategory(doc) === 'folder' ? $t('ged.folder') : $t('ged.fic') }} {{ doc.name }}
        </span>
      </div>
    </div>
  </AkDialog>

  <AkDialog ref="moveMultipleDialog"
            :cancel-label="$t('cancel')"
            :title="$t('ged.move_documents')"
            :validate-label="$t('validate')"
            width="650px"
            @validate="moveDocuments()">
    <div class="row d-flex flex-column">
      <div class="col-lg-12">
        {{
          $t('ged.move_documents_confirm') + ' :'
        }}

        <div v-for="doc in selectedDocuments" :key="doc" class="d-flex align-items-center">
          <img :alt="getThumbnailAlt(doc.mimetype)" :src="getThumbnail(doc.mimetype)"
               class="mr-3"
               style="max-width: 30px;"/>
          {{ getCategory(doc) === 'folder' ? $t('ged.folder') : $t('ged.fic') }} {{ doc.name }}
        </div>
      </div>

      <AkDropdown class-name="col-lg-6 mt-2" :options="authorizedFolders" option-value="id" option-label="name"
                  v-model="newParentId" :label="$t('ged.destination_folder')"/>
    </div>
  </AkDialog>
</template>

<style scoped>
</style>